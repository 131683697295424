import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { ConsultaPadrao } from "../../../../../components/ConsultaPadrao";
import { model_planoaula_etapa } from "../../../../../models";

export const PlanoAulaEtapa = forwardRef((props: any, ref) => {
    const [show, setShow] = useState(false);
    const [planoAula, setPlanoAula] = useState<any>();

    function openModal(_planoAula: any) {
        setPlanoAula(_planoAula);
        setShow(true);
    }

    function closeModal() {
        setShow(false);
    }

    useImperativeHandle(ref, () => ({
        openModal
    }))

    const columns = [
        { field: 'planoaulaetapaordem', header: 'Ordem' },
        { field: 'etapa.etapadescricao', header: 'Exercício' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        planoAula &&
        <Modal show={show} onHide={closeModal} centered={true} size='xl'>
            <ConsultaPadrao
                model={{
                    ...model_planoaula_etapa,
                    api_get: '/planoAula/etapas?planoaulaid=' + planoAula?.planoaulaid,
                    inputs: model_planoaula_etapa.inputs.map(input =>
                        input.id === 'planoaula.planoaulaid' ? { ...input, defaultValue: planoAula?.planoaulaid } : input
                    )
                }}
                columns={columns}
                scrollHeight='500px'
            />
        </Modal>
    )
})