import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import styled from "styled-components";
import { formatData } from "../../../commons/utils";
import Logo from '../../../Images/LogoPequena.png'
import { toast } from "react-toastify";

const PlanoAulaContainer = styled.div`
    padding: 10px;
    border-radius: 6px;
`;

const Descricao = styled.div`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 5px 5px 0px;
`;

const Data = styled.div`
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    text-align: center;
`;

const FraseDia = styled(Data)`
    margin: 20px 0px;
    margin-top: 10px;
`

const EtapasContainer = styled.div`
`;

const Etapa = styled.div`
    margin-bottom: 10px;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 500;
    background-color: #181818;
`;

const ExerciciosContainer = styled.div`
    padding-left: 20px;
    margin-top: 6px;
    font-size: 14px;
    font-style: italic;
    background-color: #212529;
    border-radius: 4px;
`;

const Exercicio = styled.div`
    border-top: 1px solid rgba(255, 255, 255, .4);
    padding: 3px;
    font-size: 12px;
`;

export const CardPlanoAula = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const [show, setShow] = useState(false);
    const [planoAula, setPlanoAula] = useState<any>();

    async function openModal(_planoaulaid?: any, _modalidid?: any, _planoauladata?: any) {
        let _api;

        if (_planoaulaid)
            _api = '/planoAula?planoaulaid=' + _planoaulaid;
        else
            _api = '/planoAula?modalidid=' + _modalidid + '&planoauladata=' + _planoauladata;

        await auth.get(_api)
            .then((response: any) => {
                const dados = response.data.dados;
                
                if (!dados.planoaulaid) {
                    toast.error('Nenhum Plano de Aula cadastrado para esta data e modalidade.');
                    return;
                } else
                    setShow(true);

                setPlanoAula(dados);
            });
    }

    function closeModal() {
        setShow(false);
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    return (
        <Modal show={show} onHide={closeModal} centered={true} size={'lg'}>
            {planoAula &&
                <PlanoAulaContainer style={{ border: '2px solid ' + planoAula.metodo.metodocor }}>
                    <img src={Logo} style={{ maxHeight: '35px', position: 'absolute' }} alt='' />
                    <Descricao>{planoAula.planoauladescricao}</Descricao>
                    <Data>{formatData(planoAula.planoauladata)}</Data>
                    {planoAula.planoaulamodfrase && <FraseDia>FRASE DO DIA: {planoAula.planoaulamodfrase}</FraseDia>}
                    <EtapasContainer>
                        {planoAula?.etapas?.map((etapa: any, i: any) => (
                            <Etapa key={i}>
                                <div>{etapa.etapa.etapadescricao}</div>
                                <ExerciciosContainer>
                                    {etapa?.etapa.exercicios?.map((exercicio: any, j: any) => (
                                        <Exercicio key={j}>{exercicio.exercicio.exercdescricao}</Exercicio>
                                    ))}
                                </ExerciciosContainer>
                            </Etapa>
                        ))}
                    </EtapasContainer>
                </PlanoAulaContainer>
            }
        </Modal>
    );
});
