import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Row, Col, Dropdown } from 'react-bootstrap';
import Logo from '../../Images/LogoPequena.png'
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { IoPeopleSharp } from "react-icons/io5";
import { RiMenuAddFill } from "react-icons/ri";
import { AppRoutes } from '../../routes';
import { DropdownItem, DropdownToggle, VbNavDropdown, VbNavDropdownHeader, VbNavDropdownItem, VbNavItem } from '../../components/VbNavbar';
import { FaBuilding } from "react-icons/fa";
import { FaComputer } from 'react-icons/fa6';
import { FaChartPie } from "react-icons/fa";
import { FaRegPaste } from "react-icons/fa6";
import { useState } from 'react';

export const Principal = () => {
    const auth = useAuth();

    const [expanded, setExpanded] = useState<any>(false);

    function handleNavItemClick() {
        setExpanded(false);
    }

    const NavItem = (props: any) => {
        return (
            <VbNavItem to={props.to} onClick={handleNavItemClick}>{props.children}</VbNavItem>
        )
    }

    const NavDropdownItem = (props: any) => {
        return (
            <VbNavDropdownItem to={props.to} onClick={handleNavItemClick}>{props.children}</VbNavDropdownItem>
        )
    }

    return (
        <div style={{ height: '100vh' }}>
            <Navbar expand="lg" className="bg-body-tertiary" style={{ padding: '2px', userSelect: 'none' }} expanded={expanded}>
                <Container>
                    <Navbar.Brand href="/">
                        <img src={Logo} style={{ maxHeight: '40px' }} alt='' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" />
                        <Nav className="me-auto">

                            <VbNavDropdown title="Dashboards">
                                <Row>
                                    <Col>
                                        <VbNavDropdownHeader icon={<FaChartPie />} title={'Prospects'}>
                                            <NavDropdownItem to="/">Geral</NavDropdownItem>
                                            <NavDropdownItem to="/dashboard/convertidos_professor">Convertidos Por Professor</NavDropdownItem>
                                        </VbNavDropdownHeader>
                                    </Col>
                                </Row>
                            </VbNavDropdown>

                            <VbNavDropdown title="Cadastros">
                                <div style={{ width: '400px' }}>
                                    <Row>
                                        <Col>
                                            <VbNavDropdownHeader icon={<IoPeopleSharp />} title={'Cliente'}>
                                                <NavDropdownItem to="/lead">Lead</NavDropdownItem>
                                                <NavDropdownItem to="/prospect">Prospect</NavDropdownItem>
                                                <NavDropdownItem to="/usuario">Usuário</NavDropdownItem>
                                                <NavDropdownItem to="/usuarioempresa">Usuário Empresa</NavDropdownItem>
                                            </VbNavDropdownHeader>

                                            <VbNavDropdownHeader icon={<RiMenuAddFill />} title={"Outros"}>
                                                <NavDropdownItem to="/aula">Aula</NavDropdownItem>
                                                <NavDropdownItem to="/local">Local</NavDropdownItem>
                                                <NavDropdownItem to="/modalidade">Modalidade</NavDropdownItem>
                                                <NavDropdownItem to="/motivo">Motivo</NavDropdownItem>
                                                <NavDropdownItem to="/privilegio">Privilégio</NavDropdownItem>
                                                <NavDropdownItem to="/tipoprospeccao">Tipo Prospecção</NavDropdownItem>
                                                <NavDropdownItem to="/turma">Turma</NavDropdownItem>
                                            </VbNavDropdownHeader>
                                        </Col>

                                        <Col>
                                            <VbNavDropdownHeader icon={<FaRegPaste />} title={'Planejamento'}>
                                                <NavDropdownItem to="/metodo">Método</NavDropdownItem>
                                                <NavDropdownItem to="/exercicio">Exercício</NavDropdownItem>
                                                <NavDropdownItem to="/etapa">Etapa</NavDropdownItem>
                                                <NavDropdownItem to="/planoaula">Plano Aula</NavDropdownItem>
                                                <NavDropdownItem to="/planoaulaempresa">Plano Aula Empresa</NavDropdownItem>
                                            </VbNavDropdownHeader>
                                        </Col>

                                    </Row>
                                </div>
                            </VbNavDropdown>

                            <VbNavDropdown title="Consultas">
                                <div style={{ width: '400px' }}>
                                    <Row>
                                        <Col>
                                            <VbNavDropdownHeader icon={<FaComputer />} title={"Plataforma"}>
                                                <NavDropdownItem to="/logs">Logs</NavDropdownItem>
                                                <NavDropdownItem to="/feedbacks">Feedbacks</NavDropdownItem>
                                            </VbNavDropdownHeader>
                                            <VbNavDropdownHeader icon={<RiMenuAddFill />} title={"Doar é Saúde"}>
                                                <NavDropdownItem to="/doacoes">Doações</NavDropdownItem>
                                            </VbNavDropdownHeader>
                                        </Col>
                                    </Row>
                                </div>
                            </VbNavDropdown>

                            <VbNavDropdown title="Kanbans">
                                <Row>
                                    <Col>
                                        <NavDropdownItem to="/controlelead">Controle Lead</NavDropdownItem>
                                        <NavDropdownItem to="/controleprospeccao">Controle Prospecção</NavDropdownItem>

                                    </Col>
                                </Row>
                            </VbNavDropdown>

                            <NavItem to="/agenda">Agenda</NavItem>

                            <VbNavDropdown title="Relatórios">
                                <Row>
                                    <Col>
                                        <NavDropdownItem to="/report_faltas">Faltas</NavDropdownItem>
                                    </Col>
                                </Row>
                            </VbNavDropdown>
                            <VbNavItem onClick={() => auth.logout()}>Sair</VbNavItem>
                        </Nav>

                        {(auth.empresas.length > 1) &&
                            <Nav className="me-auto">
                                <VbNavItem>
                                    <Dropdown>
                                        <DropdownToggle style={{ paddingTop: '14px', paddingBottom: '4px' }}>
                                            <p style={{ position: 'absolute', fontSize: '10px', top: 0, left: 14 }}>Selecione a empresa:</p>
                                            <FaBuilding style={{ marginRight: '10px' }} />
                                            {auth?.usuarioEmpresa?.empresa?.empnome}
                                        </DropdownToggle>

                                        <Dropdown.Menu>
                                            {
                                                auth.empresas.map((usuEmp: any, index: any) => (
                                                    <DropdownItem key={index} onClick={() => auth.alterUser(usuEmp)}>{usuEmp.empresa.empnome}</DropdownItem>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </VbNavItem>
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar >
            <AppRoutes />
        </div >
    )
}