import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { ConsultaPadrao } from "../../../../../components/ConsultaPadrao";
import { model_etapa_exercicio } from "../../../../../models";

export const EtapaExercicio = forwardRef((props: any, ref) => {
    const [show, setShow] = useState(false);
    const [etapa, setEtapa] = useState<any>();

    function openModal(_agenda: any) {
        setEtapa(_agenda);
        setShow(true);
    }

    function closeModal() {
        setShow(false);
    }

    useImperativeHandle(ref, () => ({
        openModal
    }))

    const columns = [
        { field: 'etapaexercordem', header: 'Ordem' },
        { field: 'exercicio.exercdescricao', header: 'Exercício' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        etapa &&
        <Modal show={show} onHide={closeModal} centered={true} size='xl'>
            <ConsultaPadrao
                model={{
                    ...model_etapa_exercicio,
                    api_get: '/etapa/exercicios?etapaid=' + etapa?.etapaid,
                    inputs: model_etapa_exercicio.inputs.map(input =>
                        input.id === 'etapa.etapaid' ? { ...input, defaultValue: etapa?.etapaid } : input
                    )
                }}
                columns={columns}
                scrollHeight='500px'
            />

        </Modal>
    )
})