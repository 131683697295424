import { useRef } from "react";
import { formatData } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_plano_aula } from "../../../../models";
import { PlanoAulaEtapa } from "./PlanoAulaEtapa";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { CardPlanoAula } from "../../../Commons/CardPlanoAula";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

export const PlanoAula = () => {
    const refEtapa = useRef<any>();
    const refCard = useRef<any>();

    const columns = [
        { field: 'planoaulaid', header: 'Código' },
        { field: 'planoauladescricao', header: 'Descrição' },
        { field: 'planoauladata', header: 'Data', body: (e: any) => formatData(e.planoauladata) },
        { field: 'metodo.metododescricao', header: 'Método' },
        { field: 'planoaulaativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    function functions(row: any) {
        return ([
            {
                label: 'Visualizar',
                click: () => refCard.current.openModal(row.planoaulaid),
                icon: <WysiwygIcon />,
                color: '#944fd4'
            },
            {
                label: 'Etapas',
                click: () => refEtapa.current.openModal(row),
                icon: <FormatListNumberedIcon />,
                color: '#309097'
            }
        ])
    }

    return (
        <>
            <ConsultaPadrao model={model_plano_aula} columns={columns} functions={functions} />
            <PlanoAulaEtapa ref={refEtapa} />
            <CardPlanoAula ref={refCard} />
        </>
    )
}