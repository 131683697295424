import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from '@fullcalendar/core/locales/pt-br';
import listPlugin from "@fullcalendar/list";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { model_agenda, model_aula } from "../../models";
import { formatHora } from "../../commons/utils";
import Calendar from "../../components/Calendar";
import { CadastroPadrao } from "../../components/CadastroPadrao";
import { ListaProspects } from "./ListaProspects";
import moment from "moment";
import styled from "styled-components";
import { AulasFuturas } from "../Consultas/Aulas";

const Card = styled.div`
    width: auto;
    height: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 4px;
    padding-left: 5px;
    background-color: rgba(255, 255, 255, .90);
    color: #343434;
    font-weight: 600;

    &:hover{
        background-color: rgba(255, 255, 255, .75);
    }
`

export const Agenda = () => {
    const auth = useAuth();

    const [events, setEvents] = useState<any>([]);

    const refCadastroPadrao = useRef<any>();
    const refLista = useRef<any>();
    const refConsultaAulas = useRef<any>();


    function populate(aulas: any) {
        const lessons: any[] = [];

        aulas?.forEach((aula: any) => {
            if (aula.aulaativo === 1) {
                if (aula.aulatipo === 1) {
                    lessons.push({
                        turmaid: aula.turma.turmaid,
                        title: aula.turma.turmadescricao,
                        start: `${aula.auladatainicio} ${aula.aulainicio}`,
                        end: `${aula.auladatainicio} ${aula.aulafim}`,
                        color: aula.turma.turmacor,
                        modalidid: aula.modalidid
                    });
                } else {
                    const startDate = new Date(`${aula.auladatainicio} ${aula.aulainicio}`);
                    const endDate = new Date('2025-12-31');

                    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
                        const dayOfWeek = date.getDay();

                        const accept = (aula.auladias & 2 ** dayOfWeek) !== 0;

                        const day = moment(date).startOf('day').format('YYYY-MM-DD');

                        if (accept) {
                            lessons.push({
                                turmaid: aula.turma.turmaid,
                                title: aula.turma.turmadescricao,
                                start: `${day} ${aula.aulainicio}`,
                                end: `${day} ${aula.aulafim}`,
                                color: aula.turma.turmacor,
                                modalidid: aula.modalidid
                            });
                        }
                    }
                }
            }
        });

        setEvents(lessons);
    }

    async function refresh() {
        auth.get(model_aula.api_get).then((response: any) => {
            populate(response.data.dados);
        });
    }

    useEffect(() => {
        refresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eventContent = (arg: any) => {
        return (
            <Card
                onClick={() => {
                    refLista.current.openModal({
                        turmaid: arg.event.extendedProps.turmaid,
                        turmadescricao: arg.event.title,
                        agendadia: moment(arg.event.start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
                        aulainicio: moment(arg.event.start, 'HH:mm').format('HH:mm'),
                        modalidid: arg.event.extendedProps.modalidid
                    })
                }}>
                {formatHora(arg.event.start) + ' - ' + arg.event.title}
            </Card>
        );
    };

    const customButtons = {
        newButton: {
            text: 'Novo',
            click: () => refCadastroPadrao.current.openModal()
        },
        listButton: {
            text: 'Aulas Futuras',
            click: () => refConsultaAulas.current.openModal()
        }
    };

    return (
        <>
            <Calendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                initialView={"timeGridWeek"}
                customButtons={customButtons}
                headerToolbar={{
                    start: "today prev,next listButton",
                    center: "title",
                    end: "timeGridWeek,timeGridDay,listWeek newButton",
                }}
                locale={ptLocale}
                height={'90vh'}
                allDaySlot={false}
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit'
                }}
                slotLabelInterval={{ minutes: 10 }}
                events={events}
                eventContent={eventContent}
                slotMinTime="06:10:00"
                slotMaxTime="21:00:00"
            />
            <CadastroPadrao ref={refCadastroPadrao} model={model_agenda} success={() => refresh()} />
            <ListaProspects ref={refLista} />
            <AulasFuturas ref={refConsultaAulas} />
        </>
    )
}

