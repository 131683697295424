import { useRef } from "react";
import { formatData } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_plano_aula_empresa } from "../../../../models";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { CardPlanoAula } from "../../../Commons/CardPlanoAula";

export const PlanoAulaEmpresa = () => {
    const refCard = useRef<any>();

    const columns = [
        { field: 'planoaulamodid', header: 'Código' },
        { field: 'planoAula.planoauladescricao', header: 'Plano Aula' },
        { field: 'modalidade.modaliddescricao', header: 'Modalidade' },
        { field: 'planoAula.planoauladata', header: 'Data', body: (e: any) => formatData(e.planoauladata) },
        { field: 'planoaulamodfrase', header: 'Frase Dia' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    function functions(row: any) {
        console.log(row)
        return ([
            {
                label: 'Visualizar',
                click: () => refCard.current.openModal(undefined, row.modalidade.modalidid, row.planoAula.planoauladata),
                icon: <WysiwygIcon />,
                color: '#944fd4'
            }
        ])
    }

    return (
        <>
            <ConsultaPadrao model={model_plano_aula_empresa} columns={columns} functions={functions} />
            <CardPlanoAula ref={refCard} />
        </>
    )
}