import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_etapa } from "../../../../models";
import { EtapaExercicio } from "./EtapaExercicio";
import { useRef } from "react";

export const Etapa = () => {
    const refExercicio = useRef<any>();

    const columns = [
        { field: 'etapaid', header: 'Código' },
        { field: 'etapadescricao', header: 'Título' },
        { field: 'etapaativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    function functions(row: any) {
        return ([
            {
                label: 'Exercícios',
                click: () => refExercicio.current.openModal(row),
                icon: <DirectionsRunIcon />,
                color: '#15686b' 
            },
        ])
    }

    return (
        <>
            <ConsultaPadrao model={model_etapa} columns={columns} functions={functions} />
            <EtapaExercicio ref={refExercicio} />
        </>
    )
}